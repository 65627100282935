import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Background from "./backgroundImage"

import Swiper from "react-id-swiper"

// CSS
import "swiper/css/swiper.css"

const SliderContent = ({ slider, blueBackground }) => {
  const params = {
    speed: 600,
    parallaxEl: {
      el: ".parallax-bg",
      value: "-23%",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  }
  return (
    <div
      className={`Slider max-w-6xl mx-auto px-4 md:px-8 xl:px-0 flex justify-center md:pt-24 ${
        blueBackground ? "md:pb-48" : "md:pb-20"
      }`}
    >
      <div className="backgroud-div py-6 md:py-0 w-full">
        <Swiper {...params}>
          {slider.map(slide => {
            const { id, clientLogo, clientReview, clientName } = slide
            return (
              <div key={id}>
                <div className="main-container h-full w-full md:w-3/4 md:px-4 py-12 md:py-20 text-center rounded-lg">
                  <div className="flex justify-center">
                    <div className="w-4/5 md:w-2/5">
                      <img
                        src={clientLogo.file.url}
                        alt={clientLogo.title}
                        className="w-full"
                        loading="lazy"
                      />
                    </div>
                  </div>

                  <p className="text-white text-lg m-4 py-2">
                    {`"${clientReview.clientReview}"`}
                  </p>
                  <p className="font-bold text-white text-lg my-4 md:m-0">
                    {clientName}
                  </p>
                </div>
              </div>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}

const Slider = ({ blueBackground }) => {
  const data = useStaticQuery(graphql`
    query SliderQuery {
      sliderSection: contentfulHomePage {
        slider {
          id
          clientName
          clientReview {
            clientReview
          }
          clientLogo {
            title
            file {
              url
            }
          }
        }
      }
    }
  `)

  const { slider } = data.sliderSection

  if (blueBackground)
    return (
      <Background
        type="blue"
        style={{
          backgroundSize: "cover",
        }}
      >
        <SliderContent slider={slider} blueBackground={blueBackground} />
      </Background>
    )
  else
    return (
      <>
        <SliderContent slider={slider} blueBackground={blueBackground} />
      </>
    )
}

export default Slider
